import http from "../http-common";
import { toast } from "react-toastify";
import {
  handleProductRequest,
  createProductSuccess,
  handleProductError,
} from "../redux/Slices/ProductSlice";
// import Swal from "sweetalert2";

export const addProduct = (data, dispatch, history) => {
  dispatch(handleProductRequest());
  http
    .post("product", data)
    .then((response) => {
      dispatch(createProductSuccess());
      toast.success("Product created successfully");
      history.push(`/edit-product/${data.slug}`);
      return true;
      //history.push(`/admin/beneficiaries/${data.customer_id}`);
    })
    .catch((error) => {
      toast.error("Created product error");
      dispatch(handleProductError());
      return false;
    });
};


export const getAllProducts = async (dispatch, pageNumber) => {
  dispatch(handleProductRequest());
  const data = await http
  .get(`products/p/1?page=${pageNumber}`)
  .then((response) => {
    dispatch(createProductSuccess());
    return response;
  }).catch((error) => {
    dispatch(handleProductError());
    return error;
  })

  return data;
}

export const getAllPendingProducts = async (dispatch, pageNumber) => {
  dispatch(handleProductRequest());
  const data = await http
  .get(`pending-products/p/1?page=${pageNumber}`)
  .then((response) => {
    dispatch(createProductSuccess());
    return response;
  }).catch((error) => {
    dispatch(handleProductError());
    return error;
  })

  return data;
}

export const getAllCategoryProducts = async (dispatch, pageNumber, categoryID) => {
  dispatch(handleProductRequest());
  const data = await http
  .get(`category-products/p/1?page=${pageNumber}&id=${categoryID}`)
  .then((response) => {
    dispatch(createProductSuccess());
    return response;
  }).catch((error) => {
    dispatch(handleProductError());
    return error;
  })

  return data;
}

export const getAllVendorCategoryProducts = async (dispatch, pageNumber, categoryID, creatorID) => {
  dispatch(handleProductRequest());
  const data = await http
  .get(`vendor-category-products/p/1?page=${pageNumber}&id=${categoryID}&creator_id=${creatorID}`)
  .then((response) => {
    dispatch(createProductSuccess());
    return response;
  }).catch((error) => {
    dispatch(handleProductError());
    return error;
  });

  return data;
}

export const getAllVendorProducts = async (dispatch, pageNumber) => {
  dispatch(handleProductRequest());
  const data = await http
  .get(`vendor-products/${pageNumber}`)
  .then((response) => {
    dispatch(createProductSuccess());
    return response;
  }).catch((error) => {
    dispatch(handleProductError());
    return error;
  })

  return data;
}

export const getVendorProducts = async (dispatch, vendorID, pageNumber) => {
  dispatch(handleProductRequest());
  const data = await http
  .get(`vendor-product/${vendorID}/${pageNumber}`)
  .then((response) => {
    dispatch(createProductSuccess());
    return response;
  }).catch((error) => {
    dispatch(handleProductError());
    return error;
  })

  return data;
}

export const getProduct = async (slug) => {
  const data = await http
    .get(`/product/${slug}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return data;
};

export const getProductCategories = async () => {
  const data = await http
    .get(`categories/product-categories`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });

  return data;
};

export const updateProduct = (data, product_id, dispatch, history) => {
  dispatch(handleProductRequest());
  
  http
    .put(`product/${product_id}`, data)
    .then((response) => {
      toast.success("Product Updated Successfully");
      dispatch(createProductSuccess());
      history.push(`/edit-product/${data.slug}`);
    })
    .catch((error) => {
      dispatch(handleProductError());
    });
};

export const updateProductReview = (data, product_id, dispatch, history) => {
  dispatch(handleProductRequest());
  
  http
    .put(`product/${product_id}`, data)
    .then((response) => {
      toast.success("Product Updated Successfully");
      dispatch(createProductSuccess());
      history.push(`/edit-product-review/${data.slug}`);
    })
    .catch((error) => {
      dispatch(handleProductError());
    });
};

export const updatePendingProduct = (product_id, dispatch, history) => {
  dispatch(handleProductRequest());
  
  http
    .put(`update-product-status/${product_id}`)
    .then((response) => {
      toast.success(response.data.message);
      dispatch(createProductSuccess());
      history.push(`/product-review`);
    })
    .catch((error) => {
      toast.error("Error updating product");
      dispatch(handleProductError());
    });
};


export const serialize = (obj) => {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const searchProduct = async (serializeSearchParam) => {
  const data = await http
  .get(`search?${serializeSearchParam}`)
  .then((response) => {
    return response;
  }).catch((error) => {
    return error;
  })

  return data;
}

export const searchVendorProduct = async (serializeSearchParam) => {
  const data = await http
  .get(`vendor-search?${serializeSearchParam}`)
  .then((response) => {
    return response;
  }).catch((error) => {
    return error;
  })

  return data;
}


