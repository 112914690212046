import { ActivityStream, ProductCard, Wrapper } from "../../components";
import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from 'react-toastify';
import { getSingleVendor, updateVendor, deactivateVendor } from "../../services/VendorServices";
import { getAllVendorCategoryProducts, getVendorProducts } from "../../services/ProductService";
import { getTermsAndConditionsDetails } from "services/UserManagementService";
import { getCategories } from "services/CategoryService";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AppBar, TextField, Toolbar } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: "inline-block",
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      width: 128,
      height: 128,
    },
    [theme.breakpoints.down("xs")]: {
      width: 64,
      height: 64,
    },
    marginBottom: theme.spacing(1),
  },
  tabRoot: {
    textTransform: "initial",
    color: theme.palette.text.primary,
  },
  postInput: {
    border: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1) * 3,
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1) * 2,
    },
    fontSize: "13px",
    outline: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

const TabContainer = (props) => (
  <Typography component="div" className="pa-0">
    {props.children}
  </Typography>
);

const EditVendor = () => {
  const classes = useStyles();
  const { vendorID } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const statusApproved = {
    padding: "5px 20px",
    backgroundColor: "#0047AB",
    borderRadius: "5px",
    color: "#fff",
    marginRight: '10px',
    border: 0,
    padding: '4px 15px'
  };

  const statusDecline = {
    padding: "5px 20px",
    backgroundColor: "#D2042D",
    borderRadius: "5px",
    color: "#fff",
    border: 0,
    padding: '4px 15px'
  };

  const statusDeactivated = {
    backgroundColor: "#9C27B0",
    borderRadius: "5px",
    color: "#fff",
    border: 0,
    padding: '4px 15px',
    marginLeft: '10px'
  };

  const [profile, setProfile] = useState({});
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState({ page: 1 });
  const [pagination, setPagination] = useState({ last_page: 0 });
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);
  const [termsAndCondtions, setTermsAndConditions] = useState(false);
  const [options, setOptions] = useState([{ label: "", value: "", id: "" }]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { pending } = useSelector(state => state.vendor);
  const { loading: categoryLoading, categories } = useSelector(
    (state) => state.category
  );

  const handleOptionSelected = (event, newValue) => {
    setSelectedOption(newValue);
  };

  useEffect(() => {
    getCategories(dispatch);
  }, []);


  const fetchVendorProducts = async () => {
    try {
      const response = await getVendorProducts(dispatch, vendorID, page.page);
      let products = [];
      response.data.data?.map((item) => {
        products.push(item);
      });
      setProducts([...products]);
      setPagination({ last_page: response.data?.last_page || 0 });
    } catch (error) { }
  }

  const fetchCategoryProducts = async () => {
    try {
      const response = await getAllVendorCategoryProducts(
        dispatch,
        page.page,
        selectedOption?.id,
        vendorID
      );

      let products = [];
      response.data.data?.map((item) => {
        products.push(item);
      });
      setProducts([...products]);
      setPagination({ last_page: response.data?.last_page || 0 });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(selectedOption !== null){
      fetchCategoryProducts();
    }else{ 
      fetchVendorProducts();
    }
    
  }, [vendorID, page, selectedOption]);

  

  const next = () => {
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    window.scrollTo(0, 0);
    props.history.push("/store/our-store?page=" + currentPage);
  };

  const prev = () => {
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      window.scrollTo(0, 0);
      props.history.push("/store/our-store?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      window.scrollTo(0, 0);
      props.history.push("/store/our-store?page=" + currentPage);
    }
  };

  const renderPagination = () => {
    const currentPage = Number(page.page);
    const lastPage = Number(pagination.last_page);
    const pagesToShow = 2; // Number of pages to show before the ellipsis

    const paginationItems = [];

    // First page
    paginationItems.push(
      <Button
        key={1}
        variant="outlined"
        className="pageLink"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
        style={{ marginRight: "10px" }}
      >
        1
      </Button>
    );

    // If current page is not too close to the beginning, add ellipsis
    if (currentPage > pagesToShow + 1) {
      paginationItems.push(<span key="ellipsis1">...</span>);
    }

    // Pages before current page
    for (let i = Math.max(2, currentPage - pagesToShow); i < currentPage; i++) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ marginRight: "10px" }}
        >
          {i}
        </Button>
      );
    }

    // Current page
    paginationItems.push(
      <Button
        key={currentPage}
        variant="contained"
        className="pageLink"
        disabled
        style={{ marginRight: "10px" }}
      >
        {currentPage}
      </Button>
    );

    // Pages after current page
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + pagesToShow, lastPage - 1);
      i++
    ) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ marginRight: "10px" }}
        >
          {i}
        </Button>
      );
    }

    // If current page is not too close to the end, add ellipsis
    if (lastPage - currentPage > pagesToShow) {
      paginationItems.push(<span key="ellipsis2">...</span>);
    }

    // Last page
    if (lastPage > 1) {
      paginationItems.push(
        <Button
          key={lastPage}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(lastPage)}
        >
          {lastPage}
        </Button>
      );
    }

    return paginationItems;
  };

  const handlePageChange = (pageNumber) => {
    setPage({ page: pageNumber });
    window.scrollTo(0, 0);
    props.history.push(`/store/our-store?page=${pageNumber}`);
  };


  const getVendorInformation = async () => {
    try {
      const response = await getSingleVendor(dispatch, vendorID);
      setProfile(...response);
    } catch (error) {
    }
  }

  const checkTermsAndConditions = async () => {
    try {
      const response = await getTermsAndConditionsDetails(vendorID);
      setTermsAndConditions(response.data);
    } catch (error) { }
  }


  const handleUpdate = async (vendorStatus) => {
    try {

      // if(!termsAndCondtions){
      //   toast.error('Users did not agree to terms');
      //   return;
      // }

      setLoadingApprove(true);
      setLoadingDecline(true);
      if (vendorStatus === 1) {
        updateVendor(0, vendorID, dispatch);
        setLoadingApprove(false);
        setLoadingDecline(true);
      } else {
        updateVendor(1, vendorID, dispatch);
        setLoadingApprove(true);
        setLoadingDecline(false);
      }

    } catch (error) {
      setLoadingApprove(false);
      setLoadingDecline(false);
    }
  };

  const handleDeactivate = async () => {
    try {
      deactivateVendor(vendorID, dispatch, history);

    } catch (error) { }
  }

  useEffect(() => {
    getVendorInformation();
    checkTermsAndConditions();
  }, [loadingApprove, loadingDecline]);

  useEffect(() => {
    if (!categoryLoading) {
      setOptions(
        categories.map((category) => ({
          label: category.category_name,
          value: category.category_name,
          id: category.id,
        }))
      );
    }
  }, [categories, categoryLoading]);

  console.log('Logging selected option === ', selectedOption);

  if (pending) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Grid container spacing={1} style={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <Typography variant="h6" gutterBottom>
                    Profile Information
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    {profile ? profile.name : ''}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {profile ? profile.phone_number : ''}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {profile ? profile.email : ''}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {profile ? profile.address : ''}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {profile ? profile.business_name : ''}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {profile ? profile.type_of_product : ''}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <div className="text-xs-center">
                    <Avatar
                      alt="Adelle Charles"
                      src={`${process.env.PUBLIC_URL}/static/avatar.png`}
                      className={classNames(classes.avatar)}
                    />
                    {/* <Typography variant="caption" className={"mb-xs"}>
                      Account Usage
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={40}
                      className={"mb-xs"}
                    />
                    <Typography variant="caption">234Gb of 879Gb</Typography> */}
                  </div>
                </Grid>
              </Grid>
            </CardContent>

            {/* <Divider />
            <CardContent className="text-xs-center">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    23,8K
                  </Typography>
                  <Typography variant="caption">Followers</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    569
                  </Typography>
                  <Typography variant="caption">Following</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    67
                  </Typography>
                  <Typography variant="caption">Posts</Typography>
                </Grid>
              </Grid>
            </CardContent> */}
            <Divider />
            <CardContent>
              <Typography variant="subtitle1" style={{ display: 'flex', justifyContent: 'center', margin: '0 -15px' }}>
                <Button
                  className="button"
                  onClick={() => handleUpdate(profile.activated)}
                  style={profile.activated === 1 ? statusDecline : statusApproved}
                >
                  {profile.activated === 1 ? 'Decline' : 'Approve'}
                </Button>
                <Button disabled={profile.activated === 2} onClick={handleDeactivate} className="button" style={statusDeactivated}>
                  Deactive
                </Button>
              </Typography>
            </CardContent>
          </Card>

        </Grid>
      </Grid>

      <Card className={classes.card} style={{marginTop: '20px'}}>
      <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" variant="h6" className="flexSpacer">
              All Products
            </Typography>
            <div className={classes.searchCategory}>
              <Autocomplete
                style={{ width: "200px", height: "auto" }}
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                value={selectedOption}
                onChange={handleOptionSelected}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Search by Category"
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>
            {/* <div className={classes.searchWrapper}>
              <form className={classNames(classes.searchForm, "mr-0")}>
                <IconButton aria-label="Search" className={classes.searchIcon}>
                  <SearchIcon />
                </IconButton>
                <input
                  className={classes.searchInput}
                  type="text"
                  id="searchBox"
                  onChange={handleSearch}
                  placeholder="Search"
                  autoFocus="true"
                />
              </form>
            </div> */}
          </Toolbar>
        </AppBar>
      <CardContent className={classes.content}>
        <Wrapper padding={false}>
          <Grid className={classes.root}>

            <main className={classes.content}>
              <Grid container spacing={1}>
                {products.length >= 1 ? (
                  products?.map((product, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                      <ProductCard
                        id={product?.id}
                        name={product?.name}
                        url={product?.url}
                        price={product?.sale_price}
                        description={product?.description}
                        sale={product?.sale_price}
                        regular={product?.regular_price}
                        slug={product?.slug}
                      />
                    </Grid>
                  ))
                ) : (
                  <Typography variant="h6">No Products</Typography>
                )}
              </Grid>
              {pagination.last_page >= 2 ? (
                <>
                  <nav>
                    <ul className="paginationMenu">
                      <li className={"menuItem"}>
                        <Button
                          variant="outlined"
                          disabled={Number(page.page) === 1}
                          className="pageLink"
                          onClick={prev}
                          style={{ marginRight: "10px" }}
                        >
                          Previous
                        </Button>
                      </li>
                      {renderPagination()}
                      <li className="menuItem">
                        <Button
                          variant="outlined"
                          className="pageLink"
                          disabled={
                            Number(page.page) === Number(pagination.last_page)
                          }
                          onClick={next}
                        >
                          Next
                        </Button>
                      </li>
                    </ul>
                  </nav>
                </>
              ) : null}
            </main>
          </Grid>
        </Wrapper>
      </CardContent>
      </Card>

    </Wrapper>
  );
};

export default EditVendor;
